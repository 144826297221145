.navbar {
    --bs-navbar-padding-x: 1.375rem;
    --bs-navbar-padding-y: 0.875rem;
    --bs-navbar-color: rgba(0, 0, 0, 0.55);
    --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-padding-y: 0.875rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.15rem;
    --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 0.925rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --bs-navbar-toggler-border-radius: 0.2rem;
    --bs-navbar-toggler-focus-width: 0.2rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    background: #222e3c;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
    position: relative
}

.sidebar_toggle {
    cursor: pointer;
    display: flex;
    height: 26px;
    width: 26px
}

.hamburger,
.hamburger:after,
.hamburger:before {
    background: #f8f9fa;;
    border-radius: 1px;
    content: "";
    cursor: pointer;
    display: block;
    height: 3px;
    transition: background .1s ease-in-out, color .1s ease-in-out;
    width: 24px
}

.hamburger {
    position: relative
}

.hamburger:before {
    position: absolute;
    top: -7.5px;
    width: 24px
}

.hamburger:after {
    bottom: -7.5px;
    position: absolute;
    width: 16px
}

.sidebar_toggle:hover .hamburger,
.sidebar_toggle:hover .hamburger:after,
.sidebar_toggle:hover .hamburger:before {
    background: #3b7ddd
}

.hamburger-right,
.hamburger-right:after,
.hamburger-right:before {
    right: 0
}


.sidebar_brand {
    color: #f8f9fa !important;
    display: block;
    font-size: 1.15rem;
    font-weight: 600;
}

.sub {
    color: #f8f9fa !important;
    font-size: 1rem ;
    font-weight: 600;
}

.navbar_nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.navbar_nav .nav-link.active,
.navbar_nav .show>.nav-link {
    color: var(--bs-navbar-active-color)
}

.navbar_nav .dropdown-menu {
    position: static
}

.navbar-text {
    color: var(--bs-navbar-color);
    padding-bottom: .5rem;
    padding-top: .5rem
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
    color: var(--bs-navbar-active-color)
}

.navbar_collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1
}

.navbar-toggler {
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    color: var(--bs-navbar-color);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    transition: var(--bs-navbar-toggler-transition)
}
