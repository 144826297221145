.content {
    direction: ltr;
    flex: 1;
    max-width: 100vw;
    padding: 0.7rem 2rem;
    width: auto;
}

.card {
    box-shadow: 0 0 0.875rem 0 rgb(33 37 41 / 5%);
    margin-bottom: 24px;

    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.25rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 0;
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0.25rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: 0.25rem;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.25rem;
    --bs-card-cap-bg: #fff;
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 12px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative;
}

.card_header {
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    color: var(--bs-card-cap-color);
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x)
}

.card_title {
    color: #000;
    font-size: 0.95rem;
    font-weight: 600
}

.card_body {
    color: var(--bs-card-color);
    flex: 1 1 auto;
}

.chart_size {
    width: 100%;
    height: 300px;
}

.table td {
    font-size: small;
}

.text_muted {
    color: #000 !important;
}

.pie_chart {
    height: 220px;
}

.progress_container {
    margin: 30px;
}

.progress_title {
    font-weight: bold;
    margin-bottom: 5px;
}

.progress_reviews {
    color: #666;
    margin-bottom: 5px;
}

.progress_bar {
    position: relative;
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.progress_bar_inner {
    height: 100%;
    background-color: #037ef3;
    border-radius: 5px 0 0 5px;
}

.progress_percentage {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    color: #666;
}


@media screen and (max-width: 1220px) {
    .pie_chart {
        margin-top: 30px;
    }
}

@media screen and (max-width: 520px)  {
    .card_title {
        font-size: 0.85rem;
    }

    .text_muted {
        font-size: 0.83rem;
    }

}

