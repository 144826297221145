.main {
    background: #f5f7fb;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 0;
    overflow: hidden;
    transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
    width: 100%
}
